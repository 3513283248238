import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { Nullable } from '../models/CustomUtilityTypes';
import { WhlModuleType } from '../models/WhlModuleType';

export default (
  permalinkRef: MaybeRefOrGetter<Nullable<string>>,
  widgetConfig?: MaybeRef<Nullable<RawWidgetConfigFragment>>,
  to?: Nullable<WhlModuleType>
): ComputedRef<string | null> => {
  return computed(() => {
    if (isEmpty(permalinkRef)) return null;

    const localePath = useLocalePath();
    const whlModuleType = useWhlModuleType();
    const moduleType = to ?? toValue(whlModuleType)!;

    const permalink = toValue(permalinkRef);

    switch (moduleType) {
      case WhlModuleType.Event:
        return widgetConfig
          ? localePath(
              prefixLinkPath('/events/detail/' + permalink, widgetConfig)
            )
          : '/events/detail/' + permalink;
      case WhlModuleType.Tour:
        return widgetConfig
          ? localePath(
              prefixLinkPath('/tours/detail/' + permalink, widgetConfig)
            )
          : '/tours/detail/' + permalink;
      case WhlModuleType.Poi:
        return widgetConfig
          ? localePath(
              prefixLinkPath('/pois/detail/' + permalink, widgetConfig)
            )
          : '/pois/detail/' + permalink;
      default:
        return null;
    }
  });
};
